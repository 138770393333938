<script>
import {hasEditPermission,currentRoleLevelName,trialActiveForClients} from '@/mixins/roleLevelPermissions';
import { mapState } from 'vuex';

export default {
	props: ['agreementData','index','hideForEdit','itemIndexToShow','forceAddNewItem'],
	data() {
		return {
			isViewMode : !hasEditPermission('section-property') 
		};
	},
	computed: {
		...mapState(['currentRoleLevel']),
		liabilities() {
			return this.agreementData.liabilities;
		},

		liability() {
			return this.agreementData.liability
			//  =  this.agreementData.liability.hasOwnProperty('additionalClausesToRecitals') 
			// 	? this.agreementData.liability 
			// 	: {...this.agreementData.liability, additionalClausesToRecitals: []};;
		},
		clientUserAgreement(){
			return trialActiveForClients() || currentRoleLevelName === 'client-sharing-user';
		},
		isUserSeparatingParty(){
			return this.currentRoleLevel.roleAssociated === 'party'
		},
		dynamicQuestions(){
			if(this.isUserSeparatingParty){
				return {
					balance: "Account balance as of the date of separation or date of the Agreement"
				}
			}
			else{
				return {
					balance: "Amount owing as of date of separation/date of agreement"
				}
			}
		}
	},
	methods: {
		newLiability() {
			return {
				exclusions: [],
				additionalClauses: [],
				additionalClausesToRecitals:[]
			};
		},
	},
	mounted(){
		this.agreementData.liability =  this.agreementData.liability.hasOwnProperty('additionalClausesToRecitals') 
			? this.agreementData.liability 
			: {...this.agreementData.liability, additionalClausesToRecitals: []};;
	}
};
</script>

<template>
	<b-card>
		<h4 slot="header" class="section-heading">Liabilities</h4>

		<p>
			Important: If no liabilities are added below, boilerplate clauses
			will appear in the Agreement stating that both parties will keep and
			be responsible for their own respective liabilities.
		</p>

		<list-item
			v-slot="{ item: liability, index: index }"
			v-model="liabilities"
			:object-prototype="newLiability"
			:testid="'liability-'"
			:hideAddBtn=hideForEdit
			:isEditView=hideForEdit
			:itemIndexToShow=itemIndexToShow
			:forceAddNewItem=forceAddNewItem
			:disabled="isViewMode"
		>
		<template v-if="!forceAddNewItem || (forceAddNewItem && index == (liabilities.length-1))">
			<input-group
				v-model="liability.institutionName"
				class="mb-2"
				input-cols="8"
				label="Name of Financial Institution or Lender"
				reset-undefined
				:testid="'liability-institution-name-'+ index"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="liability.type"
				class="my-3"
				reset-undefined
				input-cols="4"
				label="Type of account"
				:testid="'liability-type-of-account-'+ index"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="liability.lastFourDigits"
				class="my-3"
				reset-undefined
				input-cols="4"
				label="Last four digits of the account"
				placeholder="Digits (Optional)"
				:testid="'liability-last-four-digits-of-account-'+ index"
				:disabled="isViewMode"
			/>

			<input-group
				v-model="liability.balance"
				reset-undefined
				class="my-3"
				dollar
				:label="dynamicQuestions.balance"
				:testid="'liability-amount-owing-'+ index"
				:disabled="isViewMode"
			/>

			<radio-group
				v-model="liability.owner"
				class="my-3"
				:agreement-data="agreementData"
				input-cols="4"
				label="In whose name is the liability registered?"
				reset-undefined
				partner
				select
				:options="['Joint']"
				:testid="'liability-registered-name-'+ index"
				:length="'long'"
				:disabled="isViewMode"
			/>
			<template v-if="!clientUserAgreement">
				<yes-no-choice
					v-model="liability.hasExclusions"
					reset-undefined
					label="Have the parties agreed to exclude any portion of this liability?"
					class="my-3"
					:testid="'liability-has-exclusions-'+ index"
					:disabled="isViewMode"
				/>

				<conditional-group
					class="my-3"
					:condition="liability.hasExclusions"
				>
					<exclusion-table
						v-model="liability.exclusions"
						hide-reason
						:agreement-data="agreementData"
						:testid="'liability-exclusion-table-'+ index + '-'"
						:disabled="isViewMode"
					/>
				</conditional-group>
			<conditional-group :condition="liability.owner === 'Joint' ||
											liability.owner === '0' ||
											liability.owner === '1'">
				<radio-group
					v-model="liability.responsible"
					class="my-3"
					:agreement-data="agreementData"
					input-cols="4"
					label="Who will be responsible to repay the liability?"
					reset-undefined
					partner
					select
					:options="['Joint']"
					:testid="'liability-repay-responsible-'+ index"
					:length="'long'"
					:disabled="isViewMode"
				/>
				<conditional-group :condition="!((liability.owner == '0' && liability.responsible == '0') ||
											(liability.owner == '1' && liability.responsible == '1'))">
					<radio-group
						v-model="liability.plan"
						class="mb-3"
						input-cols="8"
						label="How will the liability be repaid?"
						:options="[
							{
								text: 'Paid in full when this Agreement is signed',
								value: 'immediate',
							},
							{
								text: 'Continue to pay periodic payments until paid in full',
								value: 'periodic',
							},
						]"
						reset-undefined
						select
						:testid="'liability-repay-plan-'+ index"
						:length="'long'"
						:disabled="isViewMode"
					/>
			</conditional-group>
			</conditional-group>
		</template>
		</template>
		</list-item>
		<template v-if="!clientUserAgreement">
			<additional-clauses :title="'Additional Clauses into Recitals'" :newLabel="'Add Clause to Recitals'" class="mt-4" v-model="liability.additionalClausesToRecitals" :testid="'liability-add-clause-to-recitals-'"
				:disabled="isViewMode"/>
			<additional-clauses :title="'Additional Clauses into Sections'" :newLabel="'Add Clause to Section'" class="mt-2" v-model="liability.additionalClauses" :testid="'liability-add-clause-'"
				:disabled="isViewMode"/>
		</template>
	</b-card>
</template>
